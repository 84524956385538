import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout, Badge } from "antd";
import options from "./options";
import Scrollbars from "../../components/utility/customScrollBar.js";
import Menu from "../../components/uielements/menu";
import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
import Logo from "../../components/utility/logo";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import sound from "../../assets/alert.mp3";
import depositSound from "../../assets/deposit.mp3";
import rebateSound from "../../assets/rebate.mp3";

import { listenTransaction, getCurrentUser, getCurrentUserPermissions } from "../../helpers/parseHelper";

const audio = new Audio(sound);
const depositAudio = new Audio(depositSound);
const rebateAudio = new Audio(rebateSound);

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } = appActions;
const stripTrailingSlash = (str) => {
    if (str.substr(-1) === "/") {
        return str.substr(0, str.length - 1);
    }
    return str;
};

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.onOpenChange = this.onOpenChange.bind(this);
    }
    state = {
        user: null,
        permissionItems: null,
        unseen: {
            deposit: 0,
            payout: 0,
            topup: 0,
            settlement: 0,
        },
    };
    componentDidMount = async () => {
        audio.addEventListener("ended", () => this.setState({ play: false }));
        depositAudio.addEventListener("ended", () => this.setState({ playDeposit: false }));
        rebateAudio.addEventListener("ended", () => this.setState({ playRebate: false }));

        this.loadData();
    };
    componentWillUnmount() {
        audio.removeEventListener("ended", () => this.setState({ play: false }));
        depositAudio.removeEventListener("ended", () => this.setState({ playDeposit: false }));
        rebateAudio.removeEventListener("ended", () => this.setState({ playRebate: false }));
    }
    loadData = async () => {
        //closeConnection();
        const user = await getCurrentUser();
        const permissionItems = await getCurrentUserPermissions(user);
        //console.log("permissionItems", permissionItems);
        this.setState({ user, permissionItems });
        this._listenTransaction();
    };
    _listenTransaction = async () => {
        const subscription_payout = await listenTransaction("Payout");
        subscription_payout.on("create", async (obj) => {
            ////console.log(`payout create`, obj.id);
            let { unseen } = this.state;
            unseen.payout += 1;
            this.setState({ unseen });
            this.togglePlayAlert();
        });

        const subscription_deposit = await listenTransaction("Deposit");
        subscription_deposit.on("create", async (obj) => {
            ////console.log(`deposit create`, obj.id);
            let { unseen } = this.state;
            unseen.deposit += 1;
            this.setState({ unseen });
            this.togglePlayDepositAlert();
        });

        const subscription_topup = await listenTransaction("Topup");
        subscription_topup.on("create", async (obj) => {
            ////console.log(`deposit create`, obj.id);
            let { unseen } = this.state;
            unseen.topup += 1;
            this.setState({ unseen });
            this.togglePlayRebateAlert();
        });

        const subscription_settlement = await listenTransaction("Settlement");
        subscription_settlement.on("create", async (obj) => {
            ////console.log(`deposit create`, obj.id);
            let { unseen } = this.state;
            unseen.settlement += 1;
            this.setState({ unseen });
            this.togglePlayRebateAlert();
        });
    };
    togglePlayAlert = () => {
        this.setState({ play: !this.state.play }, () => {
            this.state.play ? audio.play() : audio.pause();
        });
    };
    togglePlayDepositAlert = () => {
        this.setState({ playDeposit: !this.state.playDeposit }, () => {
            this.state.playDeposit ? depositAudio.play() : depositAudio.pause();
        });
    };
    togglePlayRebateAlert = () => {
        this.setState({ playRebate: !this.state.playRebate }, () => {
            this.state.playRebate ? rebateAudio.play() : rebateAudio.pause();
        });
    };

    handleClick(e) {
        this.props.changeCurrent([e.key]);
        if (this.props.app.view === "MobileView") {
            setTimeout(() => {
                this.props.toggleCollapsed();
                this.props.toggleOpenDrawer();
            }, 100);
        }
    }
    onOpenChange(newOpenKeys) {
        const { app, changeOpenKeys } = this.props;
        const latestOpenKey = newOpenKeys.find((key) => !(app.openKeys.indexOf(key) > -1));
        const latestCloseKey = app.openKeys.find((key) => !(newOpenKeys.indexOf(key) > -1));
        let nextOpenKeys = [];
        if (latestOpenKey) {
            nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
        }
        if (latestCloseKey) {
            nextOpenKeys = this.getAncestorKeys(latestCloseKey);
        }
        changeOpenKeys(nextOpenKeys);
    }
    getAncestorKeys = (key) => {
        const map = {
            sub3: ["sub2"],
        };
        return map[key] || [];
    };
    getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
        const { key, label, leftIcon, children } = singleOption;
        const url = stripTrailingSlash(this.props.url);
        const { unseen } = this.state;
        if (children) {
            return (
                <SubMenu
                    key={key}
                    title={
                        <span className="isoMenuHolder" style={submenuColor}>
                            <i className={leftIcon} />
                            <span className="nav-text">
                                <IntlMessages id={label} />
                            </span>
                        </span>
                    }
                >
                    {children.map((child) => {
                        const linkTo = child.withoutDashboard ? `/${child.key}` : `${url}/${child.key}`;
                        return (
                            <Menu.Item style={submenuStyle} key={child.key}>
                                <Link style={submenuColor} to={linkTo}>
                                    <IntlMessages id={child.label} />
                                </Link>
                            </Menu.Item>
                        );
                    })}
                </SubMenu>
            );
        }
        return (
            <Menu.Item
                key={key}
                onClick={() => {
                    //this.togglePlayAlert()
                    let { unseen } = this.state;
                    if (["payout", "deposit", "topup", "settlement"].includes(key)) {
                        unseen[key] = 0;
                        this.setState({ unseen });
                    }
                }}
            >
                <Link to={`${url}/${key}`}>
                    <span className="isoMenuHolder" style={submenuColor}>
                        <i className={leftIcon} />
                        <span className="nav-text">
                            <IntlMessages id={label} />
                        </span>
                        {unseen[key] ? (
                            <Badge count={unseen[key] || 0}>
                                <div style={{ fontWeight: "bold", color: "white", backgroundColor: "red", marginLeft: 20 }} />
                            </Badge>
                        ) : null}
                    </span>
                </Link>
            </Menu.Item>
        );
    };
    render() {
        const { app, toggleOpenDrawer, height } = this.props;
        const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
        const { openDrawer } = app;
        const { user, permissionItems } = this.state;
        const mode = collapsed === true ? "vertical" : "inline";
        const onMouseEnter = (event) => {
            if (openDrawer === false) {
                toggleOpenDrawer();
            }
            return;
        };
        const onMouseLeave = () => {
            if (openDrawer === true) {
                toggleOpenDrawer();
            }
            return;
        };
        const customizedTheme = themes[themeConfig.theme];
        const styling = {
            backgroundColor: customizedTheme.backgroundColor,
        };
        const submenuStyle = {
            backgroundColor: "rgba(0,0,0,0.3)",
            color: customizedTheme.textColor,
        };
        const submenuColor = {
            color: customizedTheme.textColor,
        };
        const mappedPermissions = Object.keys(permissionItems || {})?.filter((item) => permissionItems[item]?.includes("VIEW"));
        ////console.log('mappedPermissions sidebar',mappedPermissions);
        mappedPermissions.push("");
        let filter = options;

        options.forEach((item) => {
            if (!mappedPermissions.includes(item.key.toUpperCase())) {
                filter = filter.filter((menu) => menu.key !== item.key);
            }
        });

        return (
            <SidebarWrapper>
                <Sider
                    trigger={null}
                    collapsible={true}
                    collapsed={collapsed}
                    width={240}
                    className="isomorphicSidebar"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    style={styling}
                >
                    <Logo collapsed={collapsed} />
                    <Scrollbars style={{ height: height - 70 }}>
                        <Menu
                            onClick={this.handleClick}
                            theme="dark"
                            className="isoDashboardMenu"
                            mode={mode}
                            openKeys={collapsed ? [] : app.openKeys}
                            selectedKeys={app.current}
                            onOpenChange={this.onOpenChange}
                        >
                            {filter.map((singleOption) => this.getMenuItem({ submenuStyle, submenuColor, singleOption }))}
                        </Menu>
                    </Scrollbars>
                </Sider>
            </SidebarWrapper>
        );
    }
}

export default connect(
    (state) => ({
        app: state.App,
        height: state.App.height,
    }),
    { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
