const options = [
    {
        key: "",
        label: "Home",
        leftIcon: "ion-home",
    },
 
    {
        key: "booth",
        label: "Booth",
        leftIcon: "ion-android-camera",
    },
    {
        key: "printer",
        label: "Printer",
        leftIcon: "ion-ios-printer",
    },
    {
        key: "monitor",
        label: "Monitor",
        leftIcon: "ion-ios-monitor",
    },
    {
        key: "computer",
        label: "Computer",
        leftIcon: "ion-social-apple",
    },
    {
        key: "camera",
        label: "Camera",
        leftIcon: "ion-android-camera",
    },
    {
        key: "template",
        label: "Template",
        leftIcon: "ion-images",
    },
    {
        key: "frame",
        label: "frame",
        leftIcon: "ion-android-apps",
    },
    {
        key: "filter",
        label: "Filter",
        leftIcon: "ion-ios-color-wand",
    },
    {
        key: "deposit",
        label: "Deposit",
        leftIcon: "ion-ios-redo",
    },
    {
        key: "settlement",
        label: "Settlement",
        leftIcon: "ion-archive",
    },
    {
        key: "client",
        label: "Client",
        leftIcon: "ion-android-person",
    },
    {
        key: "admin",
        label: "High user",
        leftIcon: "ion-ios-contact-outline",
    },
  
    {
        key: "role",
        label: "Role & Permissions",
        leftIcon: "ion-android-hand",
    },
    {
        key: "activity",
        label: "Activity",
        leftIcon: "ion-android-walk",
    },
    {
        key: "serverLog",
        label: "Server Logs",
        leftIcon: "ion-monitor",
    },
    {
        key: "setting",
        label: "Setting",
        leftIcon: "ion-android-settings",
    },
];
export default options;
