import React, { Component } from "react";
import { Route } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";
import { getCurrentUserPermissions } from "../../helpers/parseHelper";

const routes = [
    {
        path: "",
        component: asyncComponent(() => import("../Home")),
    },
    {
        path: "serverLog",
        component: asyncComponent(() => import("../ServerLog")),
    },
    {
        path: "deposit",
        component: asyncComponent(() => import("../Deposit")),
    },
    {
        path: "settlement",
        component: asyncComponent(() => import("../Settlement")),
    },
    {
        path: "admin",
        component: asyncComponent(() => import("../AdminUser")),
    },
    {
        path: "role",
        component: asyncComponent(() => import("../Role")),
    },
    {
        path: "setting",
        component: asyncComponent(() => import("../GlobalConfig")),
    },
    {
        path: "activity",
        component: asyncComponent(() => import("../Activity")),
    },
    {
        path: "client",
        component: asyncComponent(() => import("../Client")),
    },
    {
        path: "booth",
        component: asyncComponent(() => import("../Booth")),
    },
    {
        path: "frame",
        component: asyncComponent(() => import("../Frame")),
    },
    {
        path: "boothinfo",
        component: asyncComponent(() => import("../Boothinfo")),
    },
    {
        path: "template",
        component: asyncComponent(() => import("../Template")),
    },
    {
        path: "printer",
        component: asyncComponent(() => import("../Printer")),
    },
    {
        path: "monitor",
        component: asyncComponent(() => import("../Monitor")),
    },
    {
        path: "filter",
        component: asyncComponent(() => import("../Filter")),
    },
    {
        path: "computer",
        component: asyncComponent(() => import("../Computer")),
    },
    {
        path: "camera",
        component: asyncComponent(() => import("../Camera")),
    },
];

class AppRouter extends Component {
    state = {
        filteredRoutes: null,
    };
    componentDidMount = async () => {
        const permissionItems = await getCurrentUserPermissions();
        //console.log("permissionItems on routes", permissionItems);
        const mappedPermissions = Object.keys(permissionItems || {})?.filter((item) => permissionItems[item]?.includes("VIEW"));
        //console.log("mappedPermissions route", mappedPermissions);
        mappedPermissions.push("");
        const filteredRoutes = routes.filter((item) => mappedPermissions.includes(item.path.toUpperCase()));
        //console.log("filteredRoutes", filteredRoutes);
        this.setState({ filteredRoutes });
    };
    render() {
        const { url, style } = this.props;
        const { filteredRoutes } = this.state;
        return (
            <div style={style}>
                {filteredRoutes
                    ? filteredRoutes.map((singleRoute) => {
                        const { path, exact, ...otherProps } = singleRoute;
                        return <Route exact={exact === false ? false : true} key={singleRoute.path} path={`${url}/${singleRoute.path}`} {...otherProps} />;
                    })
                    : null}
            </div>
        );
    }
}

export default AppRouter;
